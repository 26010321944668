@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.donateform {
  &__section {
    width: 100%;
    padding: 0 1rem 0 1rem;
  }
  &__title {
    padding: 2rem;

    @include tablet {
      padding: 6rem 0 4rem 0;
    }
  }
  &__subheading {
    padding: 1rem;
    @include tablet {
      padding: 2rem 0rem;
    }
  }
  &__text-body{
    max-width: 80rem;
    padding:1rem;
    margin:0 auto;
  }
  &__text1 {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 0 1rem 0;
  }
  &__mailto {
    font-weight: 700;
  }
  &__text2 {
    padding: 0 0 1rem 0;
  }
  &__addDetails--wrapper {
    display:flex;
    justify-content: center;
    align-items: center;
  }
  &__addDetails {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__addDetails-head {
    padding: 1rem 0;
  }
  &__item {
    text-align: left;
  }
  &__form {
    padding: 2rem;
    margin: 4rem auto;
    height: auto;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    border: 1px solid $secondaryC;
    border-radius: 10px;
    background-color: white;
  }
  &__subject--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25rem;
  }
  &__field {
    border-radius: 8px;
    border: 1px solid $secondaryC;
    padding: 1rem;
  }
  &__subject-field {
    width: 100%;
  }
  &__name--wrapper {
    @extend .donateform__subject--wrapper;
  }
  &__name-field {
    width: 100%;
  }
  &__email--wrapper {
    @extend .donateform__subject--wrapper;
  }
  &__email-field {
    width: 100%;
  }
  &__message--wrapper {
    @extend .donateform__subject--wrapper;
  }
  &__message-field {
    width: 100%;
    height: 20rem;
    margin-bottom: 2rem;
    font-family: $font-stack;
  }
  &__button {
    margin: 0 auto;
    height: 4rem;
    width: 80%;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    color: Black;
    background-color: rgba($secondaryA, 65%);
    border: 2px solid $primaryB;
    border-radius: 5px;
    box-shadow: 0.1rem 0.1rem 0.1rem $secondaryC;
  }
}
