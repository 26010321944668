@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.homePage {
  &__body {
    width: 100%;
  }
}

.head {
  &__hero {
    max-width: 100%;
    height: 70rem;
    background-image: url("../../assets/images/heroView.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 .4rem .4rem $secondaryC;
    position:relative;
    z-index: 2;

    @include tablet {
      height: 100rem;
    }
  }
  &__body {
    max-width: 50rem;
    margin: 0 auto 6rem auto;
    font-weight: bold;

    @include tablet {
      max-width: 60rem;
      //   height: 50rem;
    }
  }
  &__title--position {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 10rem;

    @include tablet {
      padding-top: 20rem;
    }
  }

  &__title1 {
    font-size: 5rem;
    
    font-weight: 700;
    width: 90%;
    @include tablet {
      font-size: 6rem;
      padding-bottom: 2rem;
      letter-spacing: .5px;
    }
  }
  &__title2 {
    margin-right: 2rem;
    font-size: 3.5rem;
    @include tablet {
      font-size: 4.5rem;
      letter-spacing: .5px;
    }
  }
  &__CTA--position {
    display:flex;
    flex-direction: column;
    gap:1rem;
    align-items:center;
    margin: 30rem 0 0 0;
    @include tablet {
        align-items: flex-end;
        margin: 40rem 0 0 0;
        gap: 2rem;
    }
  }
&__CTA {
    min-width: 20rem; 
    &:hover{
      font-size: 1.8rem;
      border: 4px solid $primaryB;
    }  
    
    @include tablet {
        width: 28rem;
        height: 5rem;
        font-size: 1.8rem; 
        &:hover{
          font-size: 2rem;
         
        }
      }
}}

.org {
  &__block {
    height: auto;
    background-color: $primaryC;
  }
  &__flex--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include tablet {
      flex-direction: row;
    }
  }
  &__container {
    max-width: 1080px;
    margin: 0 auto;
  }
  &__body {
    font-family: $font-stack;
    padding: 2rem 1rem 6rem 1rem;
    @include tablet {
      padding: 8rem 2rem;
    }
  }


  &__title {
    color: $primaryB;
    font-weight: 700;
    margin: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    @include tablet {
      padding-bottom: 2rem;
    }
  }
  &__feature {
    border-top: 1px solid black;
    margin-top: 1.5rem;
    width: 20rem;
  }
}
.mission {
  &__description-title {
    padding-bottom: 1rem;

    @include tablet {
      padding: 4rem 0 2rem 0;
      font-size: 2.8rem;
    }
  }
  &__list{
    padding: 0 1rem;
  }
  &__item{
    list-style: disc;
    font-weight: 600;
    line-height: 2.4rem;
  }
  &__description {
    text-align: left;
    padding-left: 1rem;
    z-index: 2;

    @include tablet {
      padding-left: 4rem;
    }
  }
  &__section {
    max-width: 50rem;
  }
}
.about {
  &__title {
    padding: 1rem;
    @include tablet{
      font-size: 3rem;
      padding: 2rem 0 4rem;
    }
  }
  &__intro {
    max-width: 80%;
    margin: 0 auto;
    line-height: 2.2rem;
    letter-spacing: .5px;
  }
}

.board {
  &__section {
    border: 1px solid $primaryB;
    box-shadow: 0.2rem 0.2rem 0.2rem $secondaryC;
    min-width: 30rem;
    max-width: 50rem;
    border-radius: 5px;
    padding: 1rem 0 2rem 0;
    margin: 2rem 0 2rem 0;

    @include tablet {
      margin-top: 10rem;
    }
  }
  &__title {
    text-decoration: underline;
    color: $primaryB;
    font-weight: 700;
    padding: 0.5rem;
  }
  &__member {
    font-weight: 600;
    line-height: 2.4rem;
  }
}

.current {
  &__body {
    height: auto;
    font-family: $font-stack;
    padding: 2rem 1rem 6rem 1rem;
    background-color: $secondaryA;
    @include tablet {
      padding: 8rem 2rem;
    }
  }
  &__title {
    color: $primaryB;
    font-weight: 700;
    margin: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    @include tablet {
      padding-bottom: 2rem;
    }
  }
  &__feature {
    border-top: 1px solid black;
    margin-top: 1.5rem;
    width: 20rem;
  }
  &__container--wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  &__text {
    max-width: 46rem;
    margin: 2rem;
    line-height: 2.2rem;
    letter-spacing: .5px;
  }
  &__imgContainer {
    max-width: 50rem;
    padding: 0 1rem;
  }
  &__imgPhoto {
    width: 100%;
    border-radius: 2px;
    box-shadow: $shadow;
  }
}
.details {
  &__body {
    height: 20rem;
    background-color: $secondaryB;
  }
}
