@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.memberpage {
  &__body {
    width: 100%;
  }
  &__hero {
    width: 100%;
    height: 70rem;
    background-image: url("../../assets/images/20221008_145430.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0.4rem 0.4rem $secondaryC;
    position: relative;
    z-index: 1;

    @include tablet {
      height: 100rem;
    }
  }
  &__filter {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    background-color: rgba(125, 125, 125, 0.6);
  }
}
.memberHero {
  &__body {
    max-width: 50rem;
    margin: 0 auto;

    @include tablet {
      max-width: 60rem;
    }
  }
  &__title--position {
    display: flex;
    flex-direction: column;
    padding: 6rem 2rem;
  }
  &__title1 {
    font-family: $font-stack;
    font-size: 4rem;
    line-height: 5rem;
    color: rgb(40, 40, 40);
  }
  &__title2 {
    font-family: $font-stack;
    font-size: 3.6rem;
    line-height: 4rem;
    font-weight: 700;
    color: rgb(40, 40, 40);
    padding-top: 4rem;
  }
}
.memberInfo {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // max-width: 80rem;
  }
  &__body {
    padding: 4rem 1rem 2rem 1rem;
    background-color: $primaryC;
  }
  &__title {
    @include tablet{
      font-size: 4rem;
    }
  }
  &__details {
    padding: 2rem;
    line-height: 2.2rem;
    letter-spacing: .5px;
    @include tablet{
      max-width: 80rem;
    }
  }
  &__benefits {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 3rem;
    max-width: 70rem;
    line-height: 2rem;
    letter-spacing: .75px;
  }
  &__item {
    font-weight: bold;
    line-height: 2.2rem;
    list-style-type: disc;
  }
}
.options {
  &__body {
    background-color: $secondaryA;
    padding: 2rem;
  }
  &__card--contain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__card {
    margin: 2rem;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(246, 242, 242);
    height: 36rem;
    width: 26rem;
    padding: 1.4rem ;

    &:hover {
      border: 2px solid $primaryB;
      box-shadow: 0.5rem 0.5rem 0.5rem $secondaryC;

      .options__title {
        font-weight: bold;
      }
      .options__buyBTN {
        display: inline-block;
      }
    }
  }
  &__title {
    padding: 1rem 0rem;
    text-decoration: underline;
  }
  &__price {
    padding-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
  }
  &__details{
    padding:1rem 0rem;
  }
  &__buyBTN {
    height: 4rem;
    width: 12rem;
    margin-top: 1.2rem;
    display: none;
  }
}
.donate {
  &__body {
    background-color: $primaryC;
    padding: 2rem 1rem 4rem 1rem;
  }
  &__heading {
    text-align: underline;
  }
  &__card {
    margin: 2rem auto;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(246, 242, 242);
    height: 36rem;
    width: 26rem;
    padding: 1.4rem;

    &:hover {
      border: 2px solid $primaryB;
      box-shadow: 0.5rem 0.5rem 0.5rem $secondaryC;

      .donate__title {
        font-weight: bold;
      }
      .donate__buyBTN {
        display: inline-block;
      }
    }
  }
  &__title {
    padding: 1rem 0;
    text-decoration: underline;
  }
  &__price {
    padding-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
  }
  &__details {
    padding: 4rem 0 2rem 0; 
  }
  &__text{
    padding: 2rem 1rem;
    max-width: 80rem;
    margin: 0 auto;
    line-height: 2.2rem;
    letter-spacing: .5px;
  }
  &__buyBTN {
    height: 4rem;
    width: 12rem;
    margin-top: 1.2rem;
    display: none;
  }
}
