@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.businessform {
  &__section {
    width: 100%;
    padding: 0 1rem 0 1rem;
  }
  &__title {
    padding: 4rem 0;

    @include tablet {
      padding: 6rem 0 4rem 0;
    }
  }
  &__text {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1rem;
    @include tablet{
        padding: 2rem;
    }
  }
  &__email{
    font-size: 1.4rem;
    font-weight: 600;
    padding: 1rem 0;
    margin: 0 auto;
    @include tablet {
        font-size: 1.6rem;
        
    }
  }
  &__form {
    padding: 1rem;
    margin: 4rem auto;
    height: auto;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    border: 1px solid $secondaryC;
    border-radius: 10px;
    background-color: white;
  }
  &__subject--wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25rem;
  }
  &__field {
    border-radius: 8px;
    border: 1px solid $secondaryC;
    padding: 1rem;
  }
  &__subject-field {
    width: 100%;
  }
  &__label {
    font-weight: 700;
  }
  &__subject-title {
    margin: 0 auto;
  }
  &__name--wrapper {
    @extend .businessform__subject--wrapper;
  }
  &__name-field {
    width: 100%;
  }
  &__email--wrapper {
    @extend .businessform__subject--wrapper;
  }
  &__email-field {
    width: 100%;
  }
  &__area--wrapper {
    @extend .businessform__subject--wrapper;
  }
  &__area-field {
    width: 100%;
  }
  &__message--wrapper {
    @extend .businessform__subject--wrapper;
  }
  &__volunteering--wrapper {
    padding-top: 2rem;
  }
  &__activity-title {
    padding: 2rem 0 1rem 0;
  }
  &__activity-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 2rem 2rem;
  }
  &__message-field {
    width: 100%;
    height: 20rem;
    margin-bottom: 2rem;
    font-family: $font-stack;
  }
  &__button {
    margin: 0 auto;
    height: 4rem;
    width: 80%;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    color: Black;
    background-color: rgba($secondaryA, 65%);
    border: 2px solid $primaryB;
    border-radius: 5px;
    box-shadow: 0.1rem 0.1rem 0.1rem $secondaryC;
  }
}
