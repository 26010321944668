@use "./variables" as *;

button {
  height: 3.6rem;
  font-size: 1.4rem;
  
  font-weight: 600;
  margin: 0;
  cursor: pointer;

  color: Black;
  background-color: rgba($secondaryA, 65%);
  border: 2px solid $primaryB;
  border-radius: 5px;
  box-shadow: 0.1rem 0.1rem 0.1rem $secondaryC;
}
