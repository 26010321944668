@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.nav {
  &__section {
    background-color: $primaryA;
    box-shadow: 0rem 0.2rem 0.1rem $secondaryC;
    position: relative;
    z-index: 10;
  }
  &__limit {
    max-width: 1080px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
  }
  &__links--wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    @include tablet {
      gap: 2rem;
      margin-right: 4rem;
    }
  }
  &__link {
    text-decoration: none;
    color: black;
    font-family: $font-stack;
    font-weight: 600;
  }
  &__link:hover {
    color: $primaryB;
    text-decoration: underline $primaryB;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.2rem;
    margin: -3px;
  }
  &__link--tablet-only {
    display: none;

    @include tablet {
      display: block;
    }
  }
  //abstract into global variable
  &__link--tablet-menu {
    background-color: $primaryA;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    @include tablet {
      display: none;
    }
  }
  &__link-menuText {
    font-family: $font-stack;
    padding-top: 0.3rem;
    font-size: 1.7rem;
    font-weight: 600;
  }
  &__link--tablet-menu:hover {
    border-bottom: 1.6px solid $primaryB;
  }
  &__link-menuText:hover {
    font-size: 1.7rem;
    font-weight: bold;
  }

  &__link--logo {
    font-family: $font-stack;
  }
  &__resumeBtn {
    width: 8rem;
  }
}
