@use "../../styles/partials/global";
@use "../../styles/partials/resets";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography";
@use "../../styles/partials/variables" as *;

.CTA {
  &__scrollTo--header {
    background-color: $primaryA;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
  }
  &__scrollTo--header:hover {
    border-bottom: 1.6px solid $primaryB;
  }
  &__scrollTo:hover {
    font-size: 1.7rem;
    font-weight: bold;
  }
  &__scrollTo {
    font-family: $font-stack;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.footer {
  &__section {
    background-color: $primaryA;
    padding: 4rem;
    min-height: 18rem;
  }
  &__limit {
    max-width: 980px;
    margin: 0 auto;
  }
  &__scrollTo--top {
    margin-left: auto;
    width: 18rem;
  }
  &__wrapper--box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include tablet {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
  &__containerA {
    display: block;
    padding-top: 2rem;

    @include tablet {
      padding: 0rem 0 0 6rem;
    }
    @include desktop {
      padding: 0 0 0 14rem;
    }
  }
  &__containerB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__block1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  &__icon-block {
    display: flex;
    align-items: center;
  }
  &__icon {
    margin-right: 1rem;
  }
  &__link {
    text-decoration: none;
    color: black;
    font-weight: 600;
  }
  &__link:hover {
    color: $primaryB;
    text-decoration: underline $primaryB;
    font-weight: 700;
  }

  &__block2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6rem;
  }
  &__block3 {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__contact--wrapper {
    display: flex;
  }
  &__email--link {
    color: black;
    text-decoration: none;
  }
  &__email-icon {
    width: 2.4rem;
  }
  &__copy {
    font-size: 1.4rem;
    font-weight: 600;
  }
  &__address-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
    padding-top: 2rem;
    font-weight: 600;
  }
}

.menu {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding-bottom: 6rem;
  }
  &__item--scroll {
    border: none;
    background-color: $primaryA;
    color: black;
    font-size: 1.8rem;
    font-weight: 600;
    padding: 0;
    height: 0;
  }
  &__item--scroll:hover {
    color: $primaryB;
    text-decoration: underline $primaryB;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.3rem;
  }
  &__item--link {
    text-decoration: none;
    color: black;
    font-size: 1.8rem;
    font-weight: 600;
  }
  &__item--link:hover {
    color: $primaryB;
    text-decoration: underline $primaryB;
    font-weight: 700;
    border: 1px solid black;
    border-radius: 4px;
    padding: 0.3rem;
  }
}
.brand {
  &__text {
    font-size: 1.4rem;
    font-weight: 600;
    padding: 2rem 0;
  }
  &__link {
    color: black;
  }
}
